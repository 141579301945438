<template>
  <!-- 通知滚动 -->
  <div class="m-slide" @mouseenter="onStop" @mouseleave="onStart">
    <div
      :style="`will-change: transform; transform: translateX(${-left}px);`"
      class="u-slide-title"
      v-for="(item, index) in sliderData"
      :key="index"
      :title="item.title"
      @click="onClickTitle(item.title)"
    >
      {{ item.title }}
    </div>
  </div>
</template>
<script>
export default {
  name: 'Slider',
  props: {
    sliderData: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      left: 0,
      moveTimer: null,
    }
  },
  // 钩子函数
  created() {
    this.onStart()
  },
  methods: {
    // 点击标题方法
    onClickTitle(title) {
      // 通知父组件点击的标题
      this.$emit('getTitle', title)
    },
    // 暂停滚动
    onStop() {
      // 暂停滚动
      clearInterval(this.moveTimer)
    },
    // 开始滚动
    onStart() {
      // 开始滚动
      if (this.sliderData.length > 0) {
        // 超过4条开始滚动
        this.getSlide()
      }
    },
    getSlide() {
      this.moveTimer = setInterval(() => {
        this.moveLeft()
      }, 5) // 1s移动200次
    },
    moveLeft() {
      if (this.left >= 320) {
        this.sliderData.push(this.sliderData.shift()) // 将第一条数据放到最后
        this.left = 0
      } else {
        this.left += 0.2 // 每次移动0.2px
      }
    },
  },
}
</script>
<style lang="less" scoped>
.m-slide {
  margin: 60px auto;
  height: 45px;
  white-space: nowrap;
  overflow: hidden;
  .u-slide-title {
    display: inline-block;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 45px;
    width: 255px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #1890ff;
    }
  }
}
</style>