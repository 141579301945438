<template>
  <div class='pageBox'>
    <div class='pageContent' style='margin-bottom: 3.75rem'>
      <!-- 背景图 -->
      <div class='bgImg'>
        <img src='../assets/images/bg.jpg' alt='' srcset='' />
      </div>

      <!-- 顶部4个导航 -->
      <!-- <div class="gridBox">
        <div class="gridItem" @click="goPath(item.url)" v-for="(item, index) in menaList" :key="index">
          <div class="gridImg">
            <img :src="item.img" alt="" set="" />
          </div>
          <div class="gridText">{{item.title}}</div>
        </div>
      </div> -->

      <!-- 搜索框 -->
      <div class='iptBox'>
        <!-- @input="iptSearch(queryParams.value)" 多余的 这个是输入框输入了就自动跟着搜索了 不用点击搜索按钮-->
        <!--  @keyup.enter="iptSearch(queryParams.value)"  回车触发搜索 -->
        <input
          type='text'
          v-model='queryParams.value'
          @keyup.enter='iptSearch(queryParams.value)'
          @input='iptSearch(queryParams.value)'
          placeholder='请输入搜索内容' />

        <div class='seacec hoverClass' @click='serch'>搜索</div>
        <!-- 清空搜索文本框 -->
        <div @click='clearSearchInput'></div>
      </div>

      <!-- 通知框 -->
      <div class='notice item_box'>
        <i class='el-icon-bell'></i>
        <div class='tit'>通知：</div>
        <div class='notice_con'>
          <Slider :sliderData='sliderData' @getTitle='getSliderTitle' />
        </div>
      </div>

      <!-- 微信公众号 -->
     <!-- <div>
       <div class='tabBox'>
         <div
           style='padding: 0 0.625rem'
           class='tabItem tabTtem1 hoverClass'
           @click='showModel'>
           官网：www.liuliangka.top
         </div>
       </div>
     </div> -->

      <div class='tabBox'>
        <!-- 第一行全部  -->
        <div
          :class="
            !queryParams.companyName
              ? 'tabItem tabTtem1 hoverClass'
              : 'tabItem hoverClass'"
          @click="tagName('')">全部
        </div>

        <!-- 移动 联通 电信 广电 对应的 -->
        <div
          :class="
            queryParams.companyName == item.name
              ? 'tabItem tabTtem1 hoverClass'
              : 'tabItem hoverClass'"
          v-for='(item, index) in homeDetail.companies'
          :key='index'
          @click='tagName(item)'>
          {{ item.name }}
        </div>
      </div>

      <div class='tabBox'>
        <!-- 第二行全部 -->
        <div
          :class="
            !queryParams.tagName
              ? 'tabItem tabTtem1 hoverClass'
              : 'tabItem hoverClass'"
          @click="companyNameItem('')">
          全部
        </div>

        <!-- 低月租 大流量 长期套餐 可选号 自己激活 通话多 对应的 -->
        <div
          :class="
            queryParams.tagName == item.name
              ? 'tabItem tabTtem1 hoverClass'
              : 'tabItem hoverClass'"
          v-for='(item, index) in homeDetail.tags'
          :key='index'
          @click='companyNameItem(item)'>
          {{ item.name }}
        </div>
      </div>

      <!-- 商品图片 -->
      <van-list :finished='finished' @load='onLoad'>
        <div class='contentCard'>
          <div
            class='cardBox'
            v-for='(item, index) in homeDetail.contents'
            :key='index'>
            <div class='cardItem'>
              <div class='cardLeft'>
                <img :src='item.image1' alt='' />
                <div class='leftTopImg'>
                  {{ index + 1 }}
                </div>
              </div>

              <!-- 商品标题 -->
              <div class='cardRight'>
                <div class='cardTitle'>{{ item.title }}</div>
                <!-- <div
                  class='cardTab'
                  v-for='(item1, index1) in item.selling_point'
                  :key='index1'>
                  {{ item1 }}
                </div> -->

                <!-- 金额0元 -->
                <div class='cardBottom'>
                  <div class='cleft'>
                    <span class='maney'>￥0</span>
                    <span class='ordmaney'>￥9.99</span>
                  </div>
                  <div class='cright'>
                    <a :href='`http://91haoka.cn/webapp/merchant/templet1.html?share_id=546347&id=${item.id}`'>免费办理</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-list>

      <!-- 商品最底部暂无更多商品 -->
      <van-divider
        contentPosition='center'
        customStyle='color: #333; border-color: #333; font-size: 1.125rem; margin: 0;'
      >暂无更多商品
      </van-divider>
      <div style="text-align: center; font-size: 13px">
        <a href="https://beian.miit.gov.cn/" target="_blank" style="">
          陕ICP备2024048515号-2
        </a>
      </div>
    </div>

    <!-- 4个导航 -->
    <div class='pageContent tabbarBottom'>
      <div class='gridBox'>
        <div class='gridItem'>
          <div class='gridImg'>
            <img src='../assets/images/icon/home-hl.png' alt='' srcset='' />
          </div>
          <div class='gridText'>流量卡①</div>
        </div>


        <div class='gridItem hoverClass'
             @click="goPath('card.wlyu.cn/index.php?s=/Home/Mobile/All/index/jxscode/2713-1035.html')">
          <div class='gridImg'>
            <img src='../assets/images/icon/nav.png' alt='' srcset='' />
          </div>
          <div class='gridText'>流量卡②</div>
        </div>

        <div class='gridItem hoverClass'
             @click="goPath('h5.gantanhao.com/?from=%3F%3FCC&timestamp=1720745282127')">
          <div class='gridImg'>
            <img src='../assets/images/icon/nav.png' alt='' srcset='' />
          </div>
          <div class='gridText'>流量卡③</div>
        </div>

        <div class='gridItem hoverClass'
             @click="goPath('www.lingtinglh.com/uzcn')">
          <div class='gridImg'>
            <img src='../assets/images/icon/lh.png' alt='' srcset='' />
          </div>
          <div class='gridText'>手机靓号</div>
        </div>

        <div class='gridItem hoverClass'
             @click="goPath('91haoka.cn/webapp/merchant/new_query.html?code=gWvAcHoWgRdu%2FJnkSa%2BIeA%3D%3D')">
          <div class='gridImg'>
            <img src='../assets/images/icon/ddcx.png' alt='' srcset='' />
          </div>
          <div class='gridText'>订单查询</div>
        </div>

        <div class='gridItem hoverClass'
             @click="goPath('app.gantanhao.com/#/pages/login/login?from=qz&timestamp=1724676574762')">
          <div class='gridImg'>
            <img src='../assets/images/icon/dl.png' alt='' srcset='' />
          </div>
          <div class='gridText'>加盟代理</div>
        </div>

        <div class='gridItem hoverClass'
             @click="goPath('app.gantanhao.com/#/pages/login/login')">
          <div class='gridImg'>
            <img src='../assets/images/icon/my.png' alt='' srcset='' />
          </div>
          <div class='gridText'>代理登录</div>
        </div>



        <!-- <div class='gridItem hoverClass'
             @click="showModel()">
          <div class='gridImg'>
            <img src='../assets/images/icon/my.png' alt='' srcset='' />
          </div>
          <div class='gridText'>联系客服</div>
        </div> -->




      </div>
    </div>

    <!-- 二维码 -->
    <van-popup v-model='dialogVisible'>
      <div>
        <img src='../assets/images/qr.png' alt='' />
      </div>
    </van-popup>
  </div>
</template>
<script>
import Slider from '@/components/message.vue' // 消息滚屏

export default {
  components: {
    Slider
  },
  data() {
    return {
      // 公众号二维码图片
      dialogVisible: false,
      loading: false,
      finished: false,
      // 通知
      sliderData: [
        {
          title: '商品搜不到就是下架了,有问题请及时添加客服微信：17688888800'
        }
      ],
      active: 0,
      // 顶部4个导航
      // menaList: [
      //   {
      //     title: '卡号套餐',
      //     img: require('../assets/images/grid1.png'),
      //     url:'t1.llk.wang'
      //   },
      //   {
      //     title: '各地好卡',
      //     img: require('../assets/images/grid2.png'),
      //     url:'t1.llk.wang'
      //   },
      //   {
      //     title: '自选靓号',
      //     img: require('../assets/images/grid3.png'),
      //     url:'t2.llk.wang'
      //   },
      //   {
      //     title: '卡号知识',
      //     img: require('../assets/images/grid4.png'),
      //     url:'t3.llk.wang'
      //   },
      //   {
      //     title: '福利活动',
      //     img: require('../assets/images/grid5.png'),
      //     url:'t4.llk.wang'
      //   },
      // ],
      // 接口返回的商品参数
      homeDetail: {
        companies: [
          {
            name: '移动',
          },
          {
            name: '联通',
          },
          {
            name: '电信',
          },
          {
            name: '广电',
          }
        ],
        contents: [],
        tags: [
          // {
          //   id: 55,
          //   name: '长期',
          //   value1: '29',
          //   created_at: '2023-03-11 17:02:07',
          //   updated_at: '2023-03-11 17:02:07'
          // },
          // {
          //   id: 54,
          //   name: '低月租',
          //   value2: '80',
          //   created_at: '2023-03-11 16:57:39',
          //   updated_at: '2023-03-11 16:57:39'
          // },
          {
            id: 53,
            name: '大流量',
            created_at: '2023-03-11 16:57:39',
            updated_at: '2023-03-11 16:57:39'
          },
          {
            id: 56,
            name: '通话多',
            created_at: '2023-03-11 17:12:15',
            updated_at: '2023-03-11 17:12:15'
          }
          // {
          //   id: 58,
          //   name: '可选号',
          //   created_at: '2023-03-11 17:22:50',
          //   updated_at: '2023-03-11 17:22:50'
          // }
          // {
          //   id: 60,
          //   name: '通话多',
          //   value3: '500',
          //   created_at: '2023-03-11 17:52:56',
          //   updated_at: '2023-03-11 17:52:56',
          // },
        ]
      },
      // 查询参数
      queryParams: {
        tagName: '', // 地区
        companyName: '', // 类型
        value: ''
      },
      // 分页
      params: {
        page: 1,
        size: 8888
      }
      // total: 20, // 总条数
    }
  },
  filteredTags() {
    return this.tags.filter((tag) => {
      // 返回 value 大于 15 的标签
      return tag.value1 < 30
    })
  },
  // 钩子函数
  created() {
    this.getProjectList()
  },
  // computed: {
  //   filteredPeople() {
  //     return this.people.filter(person => {
  //       // 过滤器条件
  //       let nameMatched = true;
  //       let ageMatched = true;
  //       if (this.query.name) {
  //         nameMatched = person.name.toLowerCase().includes(this.query.name.toLowerCase());
  //       }
  //       if (this.query.age !== null) {
  //         ageMatched = person.age === this.query.age;
  //       }
  //       return nameMatched && ageMatched;
  //     });
  //   }
  // },
  // 方法
  methods: {
    async onLoad() {
      // console.log(111)
      // // 异步更新数据
      // // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      // this.params.page++
      // const res = await this.$http.get('http://api.llk.wang/proxy/api', {
      //   params: this.params,
      // })
      // // 将获取的数据存到homeDetail中
      // this.loading = false
      // // 数据全部加载完成
      // this.homeDetail.contents = this.homeDetail.contents.concat(
      //   JSON.parse(res.data.data.list).data.data
      // )
      // if (this.homeDetail.contents.length >= this.total) {
      //   console.log('执行结束')
      //   this.finished = true
      //   this.loading = false
      // }
    },
    onRefresh() {
      // // 清空列表数据
      // this.finished = false
      // // 重新加载数据
      // // 将 loading 设置为 true，表示处于加载状态
      // this.loading = true
      // this.onLoad()
    },

    // 清空搜索文本框内容
    clearSearchInput() {
      this.queryParams.value = ''
    },
    // 获取商品
    goGoodsDetail(item) {
      window.location.href = `http://91haoka.cn/webapp/merchant/templet1.html?share_id=546347&id=${item.id}`
    },
    // 公众号二维码图片显示
    showModel() {
      this.dialogVisible = !this.dialogVisible
    },
    // 滚动通知的方法
    getSliderTitle(title) {
      // 获取点击的标题
      window.open(`http://${e}`, '_blank')
    },
    // 4个导航页面点击跳转链接的方法
    goPath(e) {
      window.open(`http://${e}`, '_blank')
    },
    //    调用接口获取项目列表
    async getProjectList() {
      const res = await this.$http.get('http://47.94.158.217:9999/proxy/api', {
        params: this.params
      })
      // 将获取的数据存到homeDetail中
      this.homeDetail.contents = JSON.parse(res.data.data.list).data.data
      // 测试输出
      // console.log(JSON.parse(res.data.data.list), 111)
      this.total = JSON.parse(res.data.data.list).data.total
    },
    // 第一个筛选 全部 移动 联通 电信 广电
    tagName(item) {
      // console.log(item, '第一个全部筛选')
      this.queryParams.tagName = ''
      this.queryParams.companyName = item.name
      // 清空搜索文本框内容
      this.clearSearchInput()
      if (!item) {
        // 获取项目列表
        this.getProjectList()
        return
      }
      this.serch()
    },

    // 计算方法第一个全部
    companyNameItem(item) {
      // 让第二个全部变成空 无选中
      this.queryParams.companyName = ''
      this.queryParams.tagName = item.name
      // 清空搜索文本框内容
      this.clearSearchInput()
      if (!item) {
        // 让第一个全部变成空 无选中
        this.queryParams.tagName = ''
        // 清空搜索文本框内容
        this.clearSearchInput()
        // 获取项目列表
        this.getProjectList()
        return
      }
      this.serch()
    },
    async threeSearch(title) {
      // this.params.page = 1;
      // this.params.size = this.total;
      await this.getProjectList()
      let filteredData = this.homeDetail.contents.filter((item) => {
        if (title == '低月租') {
          return item.title.match(/\d+/g)[0] < 29
        }
        if (title == '大流量') {
          return item.title.match(/\b([89]\d|\d{3,})(?=G\b)/)
        }
        if (title == '通话多') {
          // console.log(333);
          return item.title.match(/\b([6-9]\d{2}|[1-9]\d{3,})(?=分钟)/)
        }
      })
      // console.log(filteredData, 111)
      this.homeDetail.contents = filteredData
    },
    // 异步请求搜索方法
    async serch() {
      // this.queryParams.page =1
      if (
        this.queryParams.value ||
        this.queryParams.companyName ||
        this.queryParams.tagName
      ) {
        if (
          this.queryParams.tagName.indexOf('低月租') !== -1 ||
          this.queryParams.tagName.indexOf('大流量') !== -1 ||
          this.queryParams.tagName.indexOf('通话多') !== -1
        ) {
          this.threeSearch(this.queryParams.tagName)
          return
        }
        await this.getProjectList()
        let text =
          this.queryParams.value ||
          this.queryParams.companyName ||
          this.queryParams.tagName
        let reg = new RegExp(text)
        //利用正则规则获取符合搜索条件的数据
        let list = this.homeDetail.contents.filter((item) =>
          reg.test(item.title)
        )
        //获取符合条件的数据
        this.homeDetail.contents = list
      } else {
        //storeKey为空，则恢复全部数据显示
        this.getProjectList()
      }
    },
    // 搜索框的方法
    iptSearch(e) {
      this.serch()
      // 让第一个全部变成空 无选中
      this.queryParams.tagName = ''
      // 让第二个全部变成空 无选中
      this.queryParams.companyName = ''
    }
  }
}
</script>
<style lang='less' scoped>
input {
  border-color: transparent;
}

.pageBox {
  width: 100%;
  height: 100%;
  // background: #f5f5f5;
  background: url(../assets/images/icon/bg-1.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  min-height: 100vh;

  box-sizing: border-box;

  .pageContent {
    width: 100%;
    // padding: 0 2%;
    box-sizing: border-box;

    .bgImg {
      width: 100%;

      height: 7.8125rem;
      border-radius: 0.625rem;
      overflow: hidden;
      margin-top: 0.625rem;
      margin: 0 auto;
      padding: 0.5rem 0.5rem;
      box-sizing: border-box;

      img {
        border-radius: 0.625rem;
        width: 100%;
        height: 100%;
      }
    }

    .iptBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 0 0.625rem;
      height: 2rem;
      background-color: #fff;
      box-sizing: border-box;

      input {
        width: 80%;
        height: 1.25rem;
        border-radius: 3.125rem;
        padding-left: 0.3125rem;
        // border: .0625rem solid ;
        background: #f5f5f5;
        font-size: 12px;
      }

      .seacec {
        border: 0.0625rem solid #ccc;
        padding: 0.1875rem 0.5rem;
        font-size: 0.75rem;
        border-radius: 0.75rem;
        border: 0.0625rem solid #fe2c54;
        color: #fe2c54;
      }
    }

    .gridBox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 0.625rem;
      margin-bottom: 0.9375rem;

      .gridItem {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .gridImg {
          width: 1.25rem;
          height: 1.25rem;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .gridText {
          color: #888;
          font-size: 0.75rem;
        }
      }
    }

    .tabBox {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background-color: #fff;
      padding: 0 0.5rem;
      box-sizing: border-box;

      .tabItem {
        min-width: 3.125rem;
        height: 1.5625rem;
        border-radius: 3.125rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 9px;
        margin-bottom: 0.625rem;
        font-size: 0.75rem;
        color: #898a90;
        border: 0.0625rem solid #dbdcde;
        font-weight: 500;
        padding: 0 0.3125rem;
      }

      .tabTtem1 {
        border: 0.0625rem solid #fe2c54;
        color: #fe2c54;
      }
    }

    .contentCard {
      background-color: #fff;
      padding: 0 0.5rem;
      box-sizing: border-box;
    }

    .cardBox {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 9px;

      .cardItem {
        width: 100%;
        height: 7.3125rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.375rem 0.4375rem;
        background-color: #fff4f6;
        box-sizing: border-box;

        .leftTopImg {
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.6);
          min-width: 1.875rem;
          height: 1rem;
          color: #fff;
          text-align: center;
          line-height: 1rem;
          font-size: 12px;
          border-radius: 0 0rem 0.5rem 0rem;
        }

        .cardLeft {
          position: relative;
          width: 103px;
          height: 103px;
          border-radius: 0.625rem;
          overflow: hidden;
          margin-right: 0.3125rem;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .cardRight {
          width: 70%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          box-sizing: border-box;
        }

        .cardTitle {
          font-weight: 600;
          font-size: 0.8125rem;
        }

        .cardTab {
          // width: 1.875rem;
          display: inline-block;
          padding: 0 0.3125rem;
          border-radius: 0.375rem;
          margin-right: 0.3125rem;
          font-size: 0.75rem;
          color: #fe2c54;
        }

        .cardText {
          font-size: 1rem;
          color: #fe2c54;
        }

        .cardBottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .cleft {
          .maney {
            font-size: 0.9375rem;
            color: #fe2c54;
          }

          .ordmaney {
            font-size: 0.75rem;
            color: #999;
            text-decoration: line-through;
          }
        }

        .cright {
          width: 4.5rem;
          height: 1.5625rem;
          color: #fff;
          display: flex;
          border-radius: 0.3125rem;
          justify-content: center;
          align-items: center;
          font-size: 0.75rem;
          background: linear-gradient(174deg, #fe4fa3, #fd2c55);

          a {
            color: #fff;
          }
        }
      }
    }
  }

  .tabbarBottom {
    width: 375px;
    background: #fff;
    position: fixed;
    bottom: 0;

    .gridImg {
      border-radius: 0 !important;
    }

    img {
      width: 1.875rem;
      height: 1.875rem;
    }
  }
}

.notice {
  display: inline-flex;
  align-items: center;
  height: 3.125rem;
  background-color: #fff;
  overflow: hidden;
  color: red;
  padding: 0 8px;

  img {
    margin-right: 0.625rem;
  }

  .tit {
    width: 20%;
  }

  .notice_con {
    width: 93%;
    padding-right: 20px;
    white-space: nowrap;
    overflow: hidden;
  }
}

.hoverClass {
  cursor: pointer;
}
</style>
