<template>

        <router-view></router-view>
 

</template>
<script>
export default {
  data() {
    return {}
  },
  created() {},
  methods: {}
}
</script>
<style lang="less"  scoped>

</style>
