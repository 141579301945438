import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue'
import IndexPage from '../views/index.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: IndexPage
  }
]

const router = new VueRouter({
  routes
})

// 挂在路由导航守卫
// router.beforeEach((to, from, next) => {
//   // to 将要访问的路径
//   // from 代表从哪个路径跳转而来
//   // next 是一个函数 是否方行
//   if (to.path === '/login') return next()
//   // 获取token
//   const tokenStr = window.sessionStorage.getItem('token')
//   if (!tokenStr) return next('/login')
//   next()
// })

export default router