import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
// 导入全局样式表
import '../src/assets/css/global.css'
import VueAMap from 'vue-amap'

import axios from 'axios'
// 设置请求根路径
// axios.defaults.baseURL = 'http://127.0.0.1'
Vue.prototype.$http = axios

Vue.config.productionTip = false
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);

Vue.filter('dateFormat', function (originVal) {
  const date = new Date(originVal)
  const y = date.getFullYear()
  const m = (date.getMonth() + 1 + '').padStart(2, '0')
  const d = (date.getDate() + '').padStart(2, '0')
  // const h = (date.getHours() + '').padStart(2, '0')
  // const mm = (date.getMinutes() + '').padStart(2, '0')
  // const s = (date.getSeconds() + '').padStart(2, '0')
  // return `${y}-${m}-${d} ${h}:${mm}:${s}`
  return `${y}-${m}-${d}`
})
Vue.use(VueAMap)
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
